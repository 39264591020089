import React from "react"
import { GatsbyImage } from "gatsby-plugin-image"

const ContactBox = ({ image, pageTitle }) => (
  <div className="contactForm">
    <div className="leftWrapper">
      <form
        className="contact_form"
        name="contact-form"
        method="POST"
        action="/mail-sendt"
        data-netlify="true"
        data-netlify-honeypot="bot-field"
        enctype="multipart/form-data"
      >
        <input type="hidden" name="form-name" value="contact-form" />

        <div className="upper_wrapper">
          <div className="input_wrapper">
            <input
              placeholder="Navn*"
              type="text"
              name="navn"
              id="navn"
              required
            />
          </div>
          <div className="input_wrapper">
            <input
              placeholder="Email*"
              type="email"
              name="email"
              id="email"
              required
            />
          </div>
          <div className="input_wrapper">
            <input
              placeholder="Telefon nr.*"
              type="phone"
              name="phone"
              id="phone"
              required
            />
          </div>
        </div>

        <div className="input_wrapper message ">
          <textarea
            placeholder="Besked *"
            name="message"
            id="message"
            rows="6"
            required
          />
        </div>
        <div>
          <input
            nampe="Afsend knap"
            className="sendButton "
            type="submit"
            value="Send"
          />
        </div>
      </form>
    </div>
    <div className="rightWrapper">
      <GatsbyImage
        className="contactProfileImage"
        image={image}
        alt={pageTitle}
      />
    </div>
  </div>
)

export default ContactBox
