import React from "react"
// import { Link } from "gatsby"
import Layout from "../components/global/layout"
import SearchEngineOptimizaion from "../components/global/SearchEngineOptimizaion"
import ContactBox from "../components/pages/contact/contactBox"

import { graphql } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"
import BlockContent from "@sanity/block-content-to-react"
import serializers from "../components/sanity/serializers"
const Person = ({ data }) => (
  <Layout>
    <SearchEngineOptimizaion
      title={data.sanityPages.seo.seoTitle}
      description={data.sanityPages.seo.seoDescription}
    />

    <section className="contactPage" style={{ marginTop: "150px" }}>
      <div className="titleWrapper">
        <h1>{data.sanityPages.pageTitle}</h1> <div className="spanLine"></div>
        <BlockContent
          blocks={data.sanityPages._rawBody}
          projectId="2aoj8j2d"
          dataset="gastrologik"
          serializers={serializers}
          className="recipeBlockContent"
        />
      </div>

      <div className="contactDescription">
        <p>Så send mig en besked</p>
      </div>

      <ContactBox
        image={data.sanityAbout.profileImage.asset.gatsbyImageData}
        pageTitle={data.sanityPages.pageTitle}
      />

      <h2>Tidligere samarbejdspartnere</h2>

      <div className="collaboratorWrapper">
        <ul>
          {data.allSanityCollaborators.edges.map(function (collaborator, i) {
            return (
              <a
                href={collaborator.node.url}
                key={i + collaborator.node.url}
                target="_blank"
                rel="noreferrer"
                aria-label={collaborator.node.name}
              >
                <li>
                  <div>
                    <GatsbyImage
                      image={collaborator.node.image.asset.gatsbyImageData}
                      alt={collaborator.node.name}
                      objectPosition="bottom"
                    />
                  </div>
                </li>
              </a>
            )
          })}
        </ul>
      </div>

      <svg
        className="contactSVG"
        viewBox="0 0 200 200"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fill="#F7F7F7"
          d="M35.7,-63.2C48.4,-54.5,62.2,-49.2,72.2,-39.2C82.2,-29.3,88.4,-14.6,86.4,-1.2C84.3,12.3,74.1,24.6,63.6,33.7C53.1,42.8,42.3,48.7,31.7,58.3C21,68,10.5,81.3,-1.8,84.4C-14.1,87.6,-28.3,80.5,-41.4,72.3C-54.4,64.1,-66.4,54.7,-75.5,42.4C-84.5,30.2,-90.6,15.1,-87,2C-83.5,-11.1,-70.5,-22.1,-60.4,-32.5C-50.2,-42.8,-43.1,-52.5,-33.5,-62.9C-23.9,-73.4,-12,-84.7,-0.2,-84.3C11.5,-83.9,23,-71.8,35.7,-63.2Z"
          transform="translate(100 100)"
        />
      </svg>
    </section>
  </Layout>
)

export default Person

export const query = graphql`
  query contactQuery {
    allSanityCollaborators {
      edges {
        node {
          name
          url
          image {
            asset {
              url
              gatsbyImageData(
                backgroundColor: "#f9f9f9"
                placeholder: NONE
                width: 120
              )
            }
          }
        }
      }
    }
    sanityPages(slug: { current: { eq: "kontakt" } }) {
      pageTitle
      _rawBody
      seo {
        seoTitle
        seoDescription
      }
    }

    sanityAbout {
      profileImage {
        asset {
          url
          gatsbyImageData(
            backgroundColor: "#f9f9f9"
            placeholder: NONE

            width: 300
          )
        }
      }
    }
  }
`
